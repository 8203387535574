<template>
    <div class="h-100 w-100">
        <!-- background component -->
        <background />

        <v-card flat color="transparent" v-if="!loading && customer.weightGoal.length == 0" class="d-flex align-center" style="height:calc(100vh - 200px);">
            <v-card-text class="text-center" style="background-color:#ffff;">
                <v-form ref="goalForm">
                    <div class="headline">Antes de comenzar define a qué peso quieres llegar.</div>
                    <v-text-field v-model="weightGoal" outlined label="Peso meta" suffix="lbs" hide-details class="my-5" 
                        @keydown="numOnly" :rules="[rules.required]"></v-text-field>
                    <v-btn depressed block color="primary" class="mt-3" @click="saveWeightGoal">Guardar peso meta</v-btn>
                </v-form>
            </v-card-text>
        </v-card>

        <v-card flat class="rounded" v-else-if="!loading && progress.length > 0">
            <v-card-text>
                <div class="sticky">
                    <div class="d-flex">
                        <div class="display-2">
                            {{ current.weight == null ? 'N/A' : `${current.weight}` }} <span class="body-1" v-show="current.weight != null">lbs</span>
                        </div>
                        <v-spacer></v-spacer>

                        <v-icon :color="stars > 0 ? 'yellow darken-2' : 'grey lighten-1'" size="50">mdi-star</v-icon>
                        <div style="color:#fff;font-weight:600;text-align:center;position:absolute;right:10px;top:26px;width:30px;">
                            <span v-show="stars > 0">{{ stars }}</span>
                        </div>
                    </div>
                    <div class="caption grey--text pl-1">
                        Meta: {{ customer.weightGoal }} lbs | {{ Math.round( Math.abs( current.weight - customer.weightGoal ) * 100 ) / 100 }} lbs para llegar
                    </div>

                    <v-row class="mt-3">
                        <v-col class="text-center">
                            <div class="title font-weight-regular">{{ current.chest == null ? 'N/A' : `${current.chest}''` }}</div>
                            <div class="grey--text">Pecho</div>
                        </v-col>
                        <v-col class="text-center">
                            <div class="title font-weight-regular">{{ current.waist == null ? 'N/A' : `${current.waist}''` }}</div>
                            <div class="grey--text">Cintura</div>
                        </v-col>
                        <v-col class="text-center">
                            <div class="title font-weight-regular">{{ current.hips == null ? 'N/A' : `${current.hips}''` }}</div>
                            <div class="grey--text">Caderas</div>
                        </v-col>
                        <v-col class="text-center">
                            <div class="title font-weight-regular">{{ current.biceps == null ? 'N/A' : `${current.biceps}''` }}</div>
                            <div class="grey--text">Brazos</div>
                        </v-col>
                        <v-col class="text-center">
                            <div class="title font-weight-regular">{{ current.thighs == null ? 'N/A' : `${current.thighs}''` }}</div>
                            <div class="grey--text">Piernas</div>
                        </v-col>
                    </v-row>                    
                </div>

                <v-divider class="my-3" width="30"></v-divider>

                <v-list two-line class="pt-0">
                    <v-subheader class="px-0 mt-4 sticky-btn">
                        Mis entradas

                        <v-spacer></v-spacer>

                        <v-btn depressed fab x-small color="primary" @click="newDialog = true">
                            <v-icon >mdi-plus</v-icon>
                        </v-btn>
                    </v-subheader>

                    <template v-for="(p, ix) in progress">
                        <v-list-item class="px-0" :key="p.id" @click="viewProgress(p)">
                            <v-list-item-avatar tile class="rounded" v-if="p.image">
                                <v-img :src="p.imgSrc" cover></v-img>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title>{{ p.weight }} lbs</v-list-item-title>
                                <v-list-item-subtitle class="caption grey--text">
                                    {{ formatDate( p.measurementDate ) }} - {{ fromNow( p.measurementDate )}}
                                </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-icon color="yellow darken-2" v-show="maybeStar(ix)">mdi-star</v-icon>
                            </v-list-item-action>
                        </v-list-item>
                    </template>
                </v-list>
            </v-card-text>
        </v-card>

        <v-card flat color="transparent" v-else-if="!loading" class="d-flex align-center" style="height:calc(100vh - 200px);">
            <v-card-text class="text-center" style="background-color:#ffff;">
                <v-icon size="75" class="petu-orange mb-3">mdi-alert-circle</v-icon>
                <div class="my-5">Actualmente ninguna entrada</div>
                <v-btn depressed block color="primary" class="mt-3" @click="newDialog = true">Registrar progreso</v-btn>
            </v-card-text>
        </v-card>

        <!-- Loading dialog -->
        <v-dialog v-model="showLoadingDialog" hide-overlay persistent width="300">
            <v-card class="petu-green-bg" dark>
                <v-card-text> 
                    <span>Cargando progreso</span>
                    <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-model="newDialog" fullscreen>
            <v-card class="d-flex flex-column">
                <v-toolbar tile dark color="primary" style="flex:0 0 auto;">
                    Nueva entrada
                    
                    <v-spacer></v-spacer>
                    <v-btn icon @click="closeNewDialog">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text class="mt-5">

                    <!-- Image picker -->
                    <div class="resourceWrapper" v-show="imagesObj.resource != null || imagesObj.rscUrl.length > 0">
                        <v-img ref="image" :src="imgResource" class="imagePreview"></v-img>
                        <v-btn block depressed color="accent" style="text-transform:none;" @click="clearResources">Eliminar imagen</v-btn>
                    </div>
                    <div v-show="imagesObj.resource == null && imagesObj.rscUrl.length == 0" :class="'selectResource' + (resourceError.length > 0 ? ' resourceError' : '')" @click="showSelectDialog">
                        <v-icon size="48">mdi-plus</v-icon>
                        <div>Selecciona una imagen</div>
                    </div>
                    <input type="file" accept="image/png, image/jpeg" multiple="true" ref="picker" @change="updateImages" class="hidden" />

                    <!-- Measurements -->
                    <v-subheader class="px-0 title">Mis medidas</v-subheader>
                    <v-form ref="form">
                        <v-row>
                            <v-col cols="4" align-self="center">Peso</v-col>
                            <v-col cols="8">
                                <v-text-field v-model="weight" outlined dense placeholder="0.00" suffix="lbs" hide-details v-mask="'###.##'" :rules="[rules.required]"></v-text-field>
                            </v-col>

                            <v-col cols="4" align-self="center">Pecho</v-col>
                            <v-col cols="8">
                                <v-text-field v-model="chest" outlined dense placeholder="0.00" suffix="in" hide-details v-mask="'##.##'"></v-text-field>
                            </v-col>

                            <v-col cols="4" align-self="center">Cintura</v-col>
                            <v-col cols="8">
                                <v-text-field v-model="waist" outlined dense placeholder="0.00" suffix="in" hide-details v-mask="'##.##'"></v-text-field>
                            </v-col>

                            <v-col cols="4" align-self="center">Caderas</v-col>
                            <v-col cols="8">
                                <v-text-field v-model="hips" outlined dense placeholder="0.00" suffix="in" hide-details v-mask="'##.##'"></v-text-field>
                            </v-col>

                            <v-col cols="4" align-self="center">Brazos</v-col>
                            <v-col cols="8">
                                <v-text-field v-model="biceps" outlined dense placeholder="0.00" suffix="in" hide-details v-mask="'##.##'"></v-text-field>
                            </v-col>

                            <v-col cols="4" align-self="center">Piernas</v-col>
                            <v-col cols="8">
                                <v-text-field v-model="thighs" outlined dense placeholder="0.00" suffix="in" hide-details v-mask="'##.##'"></v-text-field>
                            </v-col>

                            <v-col cols="4" align-self="center">Fecha</v-col>
                            <v-col cols="8">
                                <v-text-field v-model="dateCreated" outlined dense placeholder="yyyy-mm-dd" v-mask="'####-##-##'"
                                    hint="yyyy-mm-dd" persistent-hint :rules="[rules.required]"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-form>

                </v-card-text>
                <v-spacer></v-spacer>
                <v-card-actions>
                    <v-btn block depressed color="secondary" style="text-transform:none;" :loading="saving" @click="save">
                        <v-icon left>mdi-floppy</v-icon>
                        Guardar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="progressDialog" fullscreen>
            <v-card class="d-flex flex-column">
                <v-toolbar tile dark color="primary" style="flex:0 0 auto;">
                    Ver entrada

                    <v-spacer></v-spacer>
                    <v-btn icon @click="progressDialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text class="mt-5" v-if="selected != null">
                    <!-- Image -->
                    <div class="resourceWrapper" v-if="selected.image">
                        <v-img ref="image" :src="selected.imgSrc" class="imagePreview"></v-img>
                    </div>

                    <!-- Measurements -->
                    <v-subheader class="px-0 title">Mis medidas</v-subheader>
                    <v-row no-gutters>
                        <v-col cols="4" class="pb-1" align-self="center">Peso</v-col>
                        <v-col cols="8" class="pb-1">
                            {{ selected.weight }} lbs
                        </v-col>

                        <v-col cols="4" class="pb-1" align-self="center">Pecho</v-col>
                        <v-col cols="8" class="pb-1">
                            {{ selected.chest == null ? 'N/A' : `${selected.chest}''` }}
                        </v-col>

                        <v-col cols="4" class="pb-1" align-self="center">Cintura</v-col>
                        <v-col cols="8" class="pb-1">
                            {{ selected.waist == null ? 'N/A' : `${selected.waist}''` }}
                        </v-col>

                        <v-col cols="4" class="pb-1" align-self="center">Caderas</v-col>
                        <v-col cols="8" class="pb-1">
                            {{ selected.hips == null ? 'N/A' : `${selected.hips}''` }}
                        </v-col>

                        <v-col cols="4" class="pb-1" align-self="center">Brazos</v-col>
                        <v-col cols="8" class="pb-1">
                            {{ selected.biceps == null ? 'N/A' : `${selected.biceps}''` }}
                        </v-col>

                        <v-col cols="4" class="pb-1" align-self="center">Piernas</v-col>
                        <v-col cols="8" class="pb-1">
                            {{ selected.thighs == null ? 'N/A' : `${selected.thighs}''` }}
                        </v-col>

                        <v-col cols="4" class="pb-1" align-self="center">Fecha</v-col>
                        <v-col cols="8" class="pb-1">
                            {{ formatDate( selected.measurementDate ) }}
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-spacer></v-spacer>
                <v-card-actions>
                    <v-btn block depressed color="secondary" style="text-transform:none;" @click="warningDialog = true">
                        <v-icon left>mdi-delete</v-icon>
                        Eliminar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="warningDialog" width="300">
            <v-card>
                <v-card-title>Alerta</v-card-title>
                <v-card-text>¿Estas segura que quieres eliminar esta entrada?</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn depressed style="text-transform:none;" @click="warningDialog = false">Cancelar</v-btn>
                    <v-btn depressed style="text-transform:none;" color="primary" :loading="saving" @click="remove">Eliminar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'; 
import { PetuCustomer } from '@/classes/customer';
import { mask } from 'vue-the-mask';
import { _st } from '@/softech';
import moment from 'moment';

import Background from '@/components/Background'

export default {
    data: () => ({
        progress        : [],
        newDialog       : false,
        progressDialog  : false,
        warningDialog   : false,
        loading         : true,
        saving          : false,

        selected        : null,
        imagesObj       : null,
        imgResource     : null,
        resourceError   : '',

        weight          : null,
        chest           : null,
        waist           : null,
        hips            : null,
        biceps          : null,
        thighs          : null,
        dateCreated     : _st.getToday(),

        weightGoal      : null,

        rules           : {
            required    : (v) => !!v || 'required',
        }
    }),
    computed: {
        ...mapGetters({
            customer    : 'auth/customer', 
            isLoggedIn  : 'auth/isLoggedIn'
        }),
        showLoadingDialog() {
            return this.loading && this.isLoggedIn && this.progress.length == 0;
        },
        current() {
            let weight, chest, waist, hips, biceps, thighs = null;
            this.progress.forEach(p => {
                weight  = weight ?? p.weight;
                chest   = chest ?? p.chest;
                waist   = waist ?? p.waist;
                hips    = hips ?? p.hips;
                biceps  = biceps ?? p.biceps;
                thighs  = thighs ?? p.thighs;
            });

            return { weight, chest, waist, hips, biceps, thighs };
        },
        stars() {
            let goal = _st.isNUE( this.customer.weightGoal ) ? 120 : this.customer.weightGoal;
            let stars = 0;

            for( let ix = 0; ix < this.progress.length; ix++ ) {
                let diff = goal - this.progress[ix].weight;
                let prevDiff = goal - this.progress[ix + 1]?.weight ?? 0;
                if( Math.abs( diff ) < Math.abs( prevDiff ) )
                    stars++;
            }
            
            return stars;
        }
    },
    created() {
        this.clearImagesObj();
    },
    async mounted() {
        try {
            if( !this.isLoggedIn ) 
                return;

            let c = new PetuCustomer( this.customer );
            this.progress = await c.getProgress();

            let progressWithImages = [];
            for( let p of this.progress ) {
                progressWithImages.push( Object.assign( { imgSrc: await this.getImageUrl( p ) }, p ) );
            }

            this.progress = progressWithImages;
        }
        finally {
            this.loading = false;
        }
    },
    methods: {
        ...mapActions({
            updateCustomer  : 'auth/updateCustomer',
        }),
        showSelectDialog() {
            this.$refs.picker.click();
        },
        clearImagesObj() {
            this.imagesObj = {
                id              : '',
                src             : { key: '', bucket: '', region: '' },
                primary         : false,
                smallThumbnail  : { key: '', bucket: '', region: '' },
                mediumThumbnail : { key: '', bucket: '', region: '' },
                rscUrl          : ''
            };
        },
        clearResources() {
            this.imagesObj.resource = null; 
            this.imagesObj.rscUrl = '';

            // destroy image resource
            this.imgResource = '';            
        },
        updateImages() {
            this.imagesObj.resource = this.$refs.picker.files[0];
            this.loadImages();
        },
        loadImages() {
            // continue if it is an image
            if( _st.isNU( this.imagesObj.resource ) && this.imagesObj.rscUrl.length > 0 ) {
                this.imgResource = this.imagesObj.rscUrl;
            }

            var reader = new FileReader();
            reader.onloadend = () => {
                this.imgResource = reader.result;
            }
            reader.readAsDataURL( this.imagesObj.resource );
        },
        closeNewDialog() {
            this.newDialog = false;
            this.clearImagesObj();
            this.imgResource = null;
            this.weight = this.chest = this.waist = this.hips = this.biceps = this.thighs = null;
            this.dateCreated = _st.getToday();
        },
        async saveWeightGoal() {
            try {
                if( !this.$refs.goalForm.validate() )
                    return;

                this.saving = true;
                this.customer.weightGoal = this.weightGoal;
                let c = new PetuCustomer( this.customer );
                await c.save();
                this.updateCustomer({ customer: c });
                this.saving = false;

                this.weightGoal = null;
            }
            catch(error) {
                this.saving = false;
                this.$root.$emit('alert', { status: 'error', message: error, centered: true });
            }
        },
        async save() {
            try {
                if( !this.$refs.form.validate() )
                    return;

                this.saving = true;
                let c = new PetuCustomer( this.customer );
                await c.saveProgress({
                    weight      : this.weight,
                    chest       : this.chest,
                    waist       : this.waist,
                    hips        : this.hips,
                    biceps      : this.biceps,
                    thighs      : this.thighs,
                    image       : this.imagesObj,
                    date        : this.dateCreated,
                });
                this.saving = false;
                this.newDialog = false;
                this.closeNewDialog();

                this.loading = true;
                this.progress = await c.getProgress();
                this.loading = false;
            }
            catch(error) {
                this.loading = false;
                this.saving = false;
                this.$root.$emit('alert', { status: 'error', message: error, centered: true });
            }
        },
        viewProgress(p) {
            this.selected = p;
            this.progressDialog = true;
        },
        async remove() {
            try {
                this.saving = true;
                let c = new PetuCustomer( this.customer );
                await c.deleteProgress( this.selected );
                this.saving = false;
                this.warningDialog = false;
                this.progressDialog = false;

                this.loading = true;
                this.progress = await c.getProgress();

                let progressWithImages = [];
                for( let p of this.progress ) {
                    progressWithImages.push( Object.assign( { imgSrc: await this.getImageUrl( p ) }, p ) );
                }

                this.progress = progressWithImages;

                this.loading = false;
            }
            catch(error) {
                this.saving = false;
                this.$root.$emit('alert', { status: 'error', message: error, centered: true }) 
            }
        },
        maybeStar(ix) {
            let goal = _st.isNUE( this.customer.weightGoal ) ? 120 : this.customer.weightGoal;
            let diff = goal - this.progress[ix].weight;
            let prevDiff = goal - this.progress[ix + 1]?.weight ?? 0;
            let result = Math.abs( diff ) < Math.abs( prevDiff );
            
            return result;
        },
        formatDate(d) {
            return moment(d).format('YYYY-MM-DD');
        },
        fromNow(d) {
            return moment(d).fromNow();
        },
        numOnly(e) {
            const key       = e.keyCode;
            const ctrlFlag  = e.ctrlKey || e.metaKey;
            
            if ([46, 8, 9, 27, 13, 110, 190].includes(key) ||    // allow: backspace, delete, tab, escape and enter
                (key == 65 && ctrlFlag) ||                  // allow: ctrl/cmd+A
                (key == 67 && ctrlFlag) ||                  // allow: ctrl/cmd+C
                (key == 88 && ctrlFlag) ||                  // allow: ctrl/cmd+X
                (key >= 35 && key <= 39)) {                 // allow: home, end, left, right
                return;                                     // do nothing
            }
      
            /** if it's a number, stop the keypress */
            if ((e.shiftKey || (key < 48 || key > 57)) && (key < 96 || key > 105)) {
                e.preventDefault();
            }
        },
        async getImageUrl( p ) {
            if( _st.isNUE( p.image ) || _st.isNUE( p.image.key ) ) 
                return Promise.resolve('Success');
            
            let c = new PetuCustomer( this.customer );
            return await c.getSrc( p.image );
        }
    },
    components: {
        Background
    },
    directives: { mask }
}
</script>

<style lang="scss" scoped>
.shadow {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px !important;
}
.sticky {
    position: sticky;
    top: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #fff;
    z-index: 3;
}
.sticky-btn {
    position: sticky;
    top: 145px;
    z-index: 3;
    background-color: #fff;
}

.selectResource {
    width: 100%;
    height: 150px;
    border-radius: 8px;
    background-color: #cdcdcd;
    text-align: center;
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
}
.selectResource.resourceError {
    background-color: #EF9A9A;
}
.hidden {
    display: none;
}
.resourceWrapper {
    margin-bottom: 20px;
}
.videoPlayer, 
.imagePreview {
    width: 100vw;
    // margin-left: -24px;
    max-height: 300px;
}
</style>